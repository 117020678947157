<template>
  <v-container fluid id="protecoesContainer" pa-0 :key="app.reloadableKey">
    <!-- scroll only carousel -->
    <v-style>
      #pcarousel .v-window__container .v-carousel__item .v-responsive__content{
      overflow-y: auto !important;
      overflow-x: hidden !important;
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
      height: calc(100vh - {{app.structure.heightBottom}} {{menu.toolbar || menu.toolbarBehavior ? " - " + app.structure.heightToolbar : ""}});
      }
    </v-style>
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="$auth.isAuthenticated()">
          <v-carousel
            id="pcarousel"
            class="elevation-0"
            :cycle="false"
            :mandatory="true"
            height="auto"
            :show-arrows="false"
            :hide-delimiter-background="true"
            :continuous="false"
            :touchless="true"
            v-model="activeTabComputed"
          >
            <v-carousel-item>
              <protecoesGrafico
                :graphID="chartId"
                :cardsID="alertsId"
                :showCadastroDialog="showCadastroDialog"
                :btnConectarEmpresa="true"
                :inCarousel="true"
                :swipeChart="true"
                :beneficios="true"
                :naoesquece="true"
                :desafiosnick="true"
                :showModeInsta="false"
              />
            </v-carousel-item>
            <v-carousel-item>
              <listaProtecoes
                :inCarousel="true"
                v-touch="{
                  right: () => swipe('right')
                }"
              />
            </v-carousel-item>
          </v-carousel>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import protecoesGrafico from "./home.vue";
import listaProtecoes from "./listaProtecoes.vue";
import { mapState, mapMutations } from "vuex";
import { Touch } from "vuetify/lib/directives";

const {
  VUE_APP_SHEETS_PROTECTION_CHART,
  VUE_APP_SHEETS_PROTECTION_ALERTS,
} = process.env;

export default {
  directives: {
    Touch
  },
  mounted() {
    if (this.activeTab == 1) this.switchTitle();
  },
  props: {
    showCadastroDialog: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialog: true,
      standByTitle: "Minhas Proteções",
      chartId: VUE_APP_SHEETS_PROTECTION_CHART,
      alertsId: VUE_APP_SHEETS_PROTECTION_ALERTS,
    };
  },
  methods: {
    ...mapMutations(["refreshReloadableMenuKey"]),
    swipe(direction) {
      if (direction == "right") {
        if (
          this.$route.params.activeTab != 0 &&
          this.$route.params.activeTab != undefined
        )
          this.$util.link("protecoes", { activeTab: 0 });
      }
    },
    switchTitle() {
      var titlebkp = this.$route.meta.title;
      this.$route.meta.title = this.standByTitle;
      this.standByTitle = titlebkp;
      this.refreshReloadableMenuKey();
    }
  },
  computed: {
    ...mapState(["app", "menu"]),
    activeTabComputed: {
      get: function() {
        return this.activeTab;
      },
      set: function(value) {
        this.$util.link("protecoes", { activeTab: value });
      }
    }
  },
  watch: {
    activeTab(newValue, oldValue) {
      this.switchTitle();
    }
  },
  components: {
    protecoesGrafico,
    listaProtecoes
  }
};
</script>

<style>
/* carrousel */
#protecoesContainer .v-carousel__controls .v-btn:before {
  opacity: 0.7 !important;
}
#protecoesContainer .v-carousel__controls .v-btn:not(.v-btn--active) {
  opacity: 0.2;
}
#protecoesContainer .v-carousel__controls .v-btn .v-btn__content .v-icon,
#protecoesContainer .v-carousel__controls .v-btn {
  width: 18px !important;
  height: 18px !important;
}
#protecoesContainer .v-carousel__controls .v-btn {
  color: var(--v-primary) !important;
}
#protecoesContainer .v-carousel__controls {
  width: auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  height: 30px !important;
}
</style>
